import styled from "styled-components";
import { FaBars, FaMap, FaPhone } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";

export const Nav = styled.nav`
  ${"" /* background: #c69963; */}
  background: #9acd32;

  height: 100px;
  display: flex;
  justify-content: space-between;
  z-index: 12;
`;

export const NavLink = styled(Link)`
  ${"" /* color: #000000; */}
  color: white;
  font-weight: bold;
  display: inline;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 2.4rem;
  font-size: 2rem;
  ${"" /* height: 100%; */}

  cursor: pointer;

  &:hover {
    transition: all 0.2s ease-in-out;
    ${"" /* background: rgba(255, 255, 255, 0.5); */}
    transform: translateY(-3px);
  }
  &.active {
    color: white;
  }
  @media screen and (max-width: 768px) {
    font-size: 3rem;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #808080;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 5rem;
    cursor: pointer;
  }
`;

export const CloseBars = styled(AiOutlineClose)`
  display: none;
  color: white;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: -1rem;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 5rem;
    cursor: pointer;
  }
`;

export const Map = styled(FaMap)`
  display: none;
  color: #808080;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 10rem;
    transform: translate(-100%, 75%);
    font-size: 5rem;
    cursor: pointer;
  }
`;
export const Phone = styled(FaPhone)`
  display: none;
  color: #808080;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 20rem;
    transform: translate(-100%, 75%);
    font-size: 5rem;
    cursor: pointer;
  }
`;
export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;

  ${"" /* option to open menu in mobile */}
  @media screen and (max-width: 768px) {
    ${"" /* align-items: left; */}
    position: absolute;

    height: 40vh;
    width: 100%;

    flex-direction: column;

    background-color: #008ad8;
    opacity: 1;
    padding: 1rem 2rem;

    transition: 0.2s ease-out;

    transform: ${({ openDrawer }) =>
      openDrawer ? `translateX(0)` : `translateX(100%)`};
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;
// Button "Rendez-vous"
export const NavBtnLink = styled(Link)`
  border-radius: 10px;
  background: #008ad8;
  ${"" /* background: #9acd32; */}
  margin-right: 1rem;
  padding: 1rem 1rem;
  ${"" /* color: #030000; */}
  color: white;
  font-weight: bold;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-size: 1.2rem;
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #0078bd;
    scale: 1.05;

    color: #fff;
  }
  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`;
