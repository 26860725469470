import React from "react";
import Sidebar from "../assets/wrappers/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "./Footer";
import ServiceWrapper from "../assets/wrappers/ServiceWrapper";
import endo from "../assets/images/servicesOfferstDetailles/endo.avif";
import ortho from "../assets/images/servicesOfferstDetailles/ortho.avif";
import pedo from "../assets/images/servicesOfferstDetailles/pediatric.avif";
import hyg from "../assets/images/servicesOfferstDetailles/nettoyage.avif";
import exo from "../assets/images/servicesOfferstDetailles/extraction.avif";
import resto from "../assets/images/servicesOfferstDetailles/resto.avif";
import implant from "../assets/images/servicesOfferstDetailles/implant.avif";
import paro from "../assets/images/servicesOfferstDetailles/perio.avif";
import esth from "../assets/images/service/esthetic.avif";
import dentier from "../assets/images/servicesOfferstDetailles/dentier.avif";
import partiel from "../assets/images/servicesOfferstDetailles/partial.avif";
import couronne from "../assets/images/servicesOfferstDetailles/couronne.avif";
import pont from "../assets/images/servicesOfferstDetailles/pont.avif";
import styled from "styled-components";
import { Helmet, HelmetProvider } from "react-helmet-async";

const ImageFit = styled.img`
  width: 80%;
  height: 80%;
`;
const ImageFitSmall = styled.img`
  width: 100%;
  height: 100%;
`;
const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2rem;
  justify-content: center;
  align-items: center;
`;

const Center = styled.h1`
  margin-top: 2rem;
  text-align: center;
`;

const SpaceP = styled.p`
  margin-bottom: 1rem;
  ${"" /* margin-right: 0.6rem; */}
  padding: 0 1 rem;
  font-size: 1.2rem;
`;

const OlList = styled.ol`
  list-style-type: decimal;
  margin-left: 2em;
`;
const LiP = styled.li`
  margin-bottom: 0.4rem;
`;

const H2 = styled.h2`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const HalfServiceWrapper = styled.div`
  padding: 0 1rem;
`;
const ServiceWeb = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Nos Services Dentaires | Dentiste à Verdun</title>
        <meta
          name="description"
          content="Des services dentaires de qualité pour un sourire éclatant et une santé bucco-dentaire optimale. Soins complets, prévention, traitements esthétiques et fonctionnels, implants, urgences, et plus."
        />
      </Helmet>
      <Sidebar>
        <Navbar />
      </Sidebar>
      <Center id="dg">Nos Services Dentaires </Center>
      {/* <p ></p> */}
      <div></div>
      <ServiceWrapper>
        <HalfServiceWrapper>
          <H2>Dentisterie Générale</H2>
          <h3>Examen et Nettoyage </h3>
          <SpaceP>
            L'examen et le nettoyage dentaires sont des procédures courantes en
            dentisterie visant à maintenir la santé de vos dents et de vos
            gencives. Lors de cet examen, le dentiste effectue un examen visuel
            et tactile de vos dents, de vos gencives et de votre mâchoire pour
            détecter tout problème éventuel, comme des caries, des infections,
            des lésions ou des problèmes de mâchoire.
          </SpaceP>

          <SpaceP>
            Le nettoyage dentaire consiste à enlever le tartre et la plaque qui
            se sont accumulés sur vos dents et vos gencives. Le tartre est un
            dépôt dur et jaunâtre qui se forme sur les dents et qui peut causer
            des caries et des problèmes de gencives s'il n'est pas enlevé. La
            plaque est un film de bactéries qui se forme constamment sur les
            dents et qui peut également causer des problèmes de santé
            bucco-dentaire s'il n'est pas enlevé régulièrement.
          </SpaceP>
          <SpaceP>
            Le nettoyage dentaire est une procédure indolore et rapide qui peut
            être réalisée en une seule séance. Il est recommandé de se faire
            nettoyer les dents tous les 6 mois ou tous les ans, selon les
            besoins de chaque patient. Cela peut aider à prévenir les caries et
            les problèmes de gencives et à maintenir une bonne santé
            bucco-dentaire.
          </SpaceP>
          <h3>Obturations (Plombages)</h3>
          <SpaceP>
            Elles permettent de remplacer une partie de la dent qui a été perdue
            en raison de caries ou de traumatismes. On utilise généralement une
            résine composite pour remplir le creux de la dent et lui redonner sa
            forme originale.
          </SpaceP>
          <H2>Service d'endodontie (Traitement de canal) </H2>
          <SpaceP>
            Le traitement de canal (ou endodontie) est une procédure dentaire
            visant à enlever la pulpe (tissu mou à l'intérieur de la dent
            contenant les nerfs et les vaisseaux sanguins) infectée ou inflammée
            de la dent. La pulpe est enlevée pour éviter que l'infection ne se
            propage et ne cause des dommages aux tissus environnants, tels que
            l'os de la mâchoire.
          </SpaceP>

          <SpaceP>
            Le traitement de canal est une procédure relativement courante et
            peut être réalisée en une ou plusieurs séances, selon la complexité
            de la dent et de l'infection. Il est important de suivre les
            instructions de votre dentiste pour assurer la réussite de la
            procédure et éviter tout problème de santé bucco-dentaire à long
            terme.
          </SpaceP>
          <H2>Extraction dentaire (Dents de Sagesse) </H2>
          <SpaceP>
            L'extraction dentaire est une procédure dentaire consistant à
            enlever une dent de la bouche. Après l'extraction, il est recommandé
            de suivre les instructions de votre dentiste pour favoriser une
            guérison rapide et prévenir les complications.
          </SpaceP>
        </HalfServiceWrapper>
        <HalfServiceWrapper>
          <ImageGrid>
            <figure>
              <ImageFitSmall src={hyg} alt="hygiene" />
            </figure>
            <figure>
              <ImageFitSmall src={endo} alt="endo" />
            </figure>
            <figure>
              <ImageFitSmall src={exo} alt="exo1" />
            </figure>
            <figure>
              <ImageFitSmall src={resto} alt="resto" />
            </figure>
          </ImageGrid>
        </HalfServiceWrapper>
      </ServiceWrapper>
      <ServiceWrapper>
        <HalfServiceWrapper>
          <figure>
            <ImageFit src={ortho} alt="Salle entre 1" />
          </figure>
        </HalfServiceWrapper>
        <HalfServiceWrapper id="ORTHO">
          <H2>Orthodontie</H2>
          <SpaceP>
            L'orthodontie est un domaine de la dentisterie qui vise à corriger
            les problèmes de positionnement des dents et à améliorer
            l'alignement des mâchoires.
          </SpaceP>
          <SpaceP>
            L'orthodontie peut être réalisée à tout âge, mais elle est
            généralement recommandée aux enfants et aux adolescents, lorsque les
            dents et les mâchoires sont encore en développement. Elle peut durer
            plusieurs mois ou plusieurs années, selon la complexité du problème
            et du traitement choisi. Elle peut aider à améliorer l'esthétique
            des dents et à prévenir les problèmes de santé bucco-dentaire à long
            terme.
          </SpaceP>
          <h2>Venez rencontrer notre orthodontiste Dre Kalina Pluta.</h2>
        </HalfServiceWrapper>
      </ServiceWrapper>

      <ServiceWrapper>
        <HalfServiceWrapper id="DP">
          <H2>Dentisterie Pédiatrique</H2>

          <SpaceP>
            La premiere visite de votre enfant chez le dentiste est une étape
            importante qui mérite d'être encouragée! L'Association dentaire
            canadienne recommande l'évaluation des nourrissons, par un dentiste,
            6 mois suivant la poussée de la première dent ou à l'âge d'un an.
          </SpaceP>
          <SpaceP>
            Même si votre enfant paraît non coopératif, cette première visite
            est importante car elle permet au dentiste d'établir une bonne
            relation avec lui et de vous fournir de précieux conseils sur les
            soins bucco-dentaires optimaux à lui donner. L'enseignement de
            bonnes habitudes d'hygiène dentaire va mettre votre enfant sur la
            voie d'une bonne santé buccodentaire et d'une bonne santé globale et
            ce, tout au long de sa vie.
          </SpaceP>
          <SpaceP>
            La prévention est une autre raison pour que votre enfant visite le
            dentiste dès son jeune âge, avant l'apparition d'un problème plus
            sérieux avec ses dents. Par la suite, un examen dentaire fait aux
            six mois permettra au dentiste de votre enfant de prévenir d'autres
            problèmes rapidement.
          </SpaceP>
          <SpaceP>Vaut mieux prévenir que guérir!</SpaceP>
          <H2>
            Consultez nos dentistes pédiatriques Dre Elaine Larochelle et Dr Duy
            Dat Vu.
          </H2>
        </HalfServiceWrapper>
        <HalfServiceWrapper>
          <figure>
            <ImageFit src={pedo} alt="Salle entre 1" />
          </figure>
        </HalfServiceWrapper>
      </ServiceWrapper>
      <ServiceWrapper>
        <HalfServiceWrapper>
          <figure>
            <ImageFit src={implant} alt="Salle entre 1" />
          </figure>
        </HalfServiceWrapper>
        <HalfServiceWrapper id="implant">
          <h2>Service d'implantologie</h2>
          <SpaceP>
            Un implant dentaire est une vis en titane qui est insérée dans l'os
            de la mâchoire et qui sert de support à une prothèse dentaire. Les
            prothèses peuvent être des couronnes, des ponts ou des dentiers,
            selon les besoins de chaque patient.
          </SpaceP>

          <SpaceP>
            Les implants dentaires sont utilisés pour remplacer une ou plusieurs
            dents manquantes ou endommagées. Ils offrent une alternative aux
            prothèses amovibles (dentiers) ou aux ponts (prothèses fixes qui
            sont soutenues par les dents adjacentes) et permettent de maintenir
            la stabilité et la force de la mâchoire.
          </SpaceP>
        </HalfServiceWrapper>
      </ServiceWrapper>
      <ServiceWrapper>
        <HalfServiceWrapper id="PARO">
          <H2>Service de Parodontie</H2>
          <SpaceP>
            La parodontie est un domaine de la dentisterie qui vise à maintenir
            la santé des gencives et des os qui soutiennent les dents. Elle peut
            être recommandée pour plusieurs raisons, comme :
          </SpaceP>
          <OlList>
            <LiP>Des gencives inflammées ou saignantes (gingivite)</LiP>
            <LiP>
              Des gencives qui se rétractent et qui révèlent la racine de la
              dent (parodontite){" "}
            </LiP>
            <LiP>
              Des dents qui se déplacent ou qui deviennent instables Des os de
              la mâchoire qui se résorbent (perte osseuse)
            </LiP>
          </OlList>
          <SpaceP>Le service de parodontie comprend plusieurs étapes : </SpaceP>
          <OlList>
            <LiP>
              Examen dentaire :le dentiste fait un examen visuel et tactile des
              gencives, des dents et de la mâchoire pour évaluer l'état de la
              santé parodontale. Il peut également effectuer des radiographies
              pour visualiser l'os et la structure des dents.
            </LiP>
            <LiP>
              Nettoyage des dents : consite à enlèver le tartre et la plaque qui
              se sont accumulés sur les dents et les gencives. Cette procédure
              s'appelle le détartrage.{" "}
            </LiP>
            <LiP>
              Traitement de la gingivite : si les gencives sont inflammées, le
              dentiste peut prescrire des médicaments ou des bains de bouche
              pour réduire l'inflammation.
            </LiP>

            <LiP>
              Traitement de la parodontite : si les gencives sont gravement
              inflammées et que l'os de la mâchoire est affecté, le dentiste
              peut recommander une chirurgie parodontale pour enlever la plaque
              et le tartre qui se sont accumulés sous la gencive. La parodontie
              est importante pour maintenir la santé des gencives et des dents à
              long terme. Elle peut aider à prévenir la perte de dents et à
              maintenir une bonne santé bucco-dentaire.
            </LiP>
          </OlList>
        </HalfServiceWrapper>
        <HalfServiceWrapper>
          <figure>
            <ImageFit src={paro} alt="traitement de parodontie" />
          </figure>
        </HalfServiceWrapper>
      </ServiceWrapper>
      <ServiceWrapper>
        <HalfServiceWrapper>
          <figure>
            <ImageFit src={esth} alt="esthetique dentaire" />
          </figure>
        </HalfServiceWrapper>
        <HalfServiceWrapper>
          <H2>Service d'esthétique</H2>
          <SpaceP>
            L'esthétique dentaire se concentre sur l'amélioration de l'apparence
            des dents et de la bouche. Elle peut inclure des traitements tels
            que le blanchiment des dents, la pose de facettes ou de couronnes,
            la modification de la forme ou de la taille des dents, et la
            correction de l'alignement des dents.
          </SpaceP>
        </HalfServiceWrapper>
      </ServiceWrapper>
      <ServiceWrapper>
        <HalfServiceWrapper>
          <H2>Prothèses Dentaires Fixe et amovibles</H2>
          <h3>Pont et couronne</h3>
          <SpaceP>
            Un pont est une prothèse fixe qui remplace une ou plusieurs dents
            manquantes. Il est composé de deux couronnes qui sont fixées sur les
            dents adjacentes aux dents manquantes (les dents piliers) et d'une
            ou plusieurs dents (pontiques) qui sont suspendues entre les
            couronnes. Le pont est fixé sur les dents piliers grâce à du ciment
            dentaire et est conçu pour être indémontable.
          </SpaceP>
          <SpaceP>
            Une couronne est une prothèse qui recouvre entièrement une dent et
            qui lui donne une forme et une taille identiques à celles d'une dent
            naturelle. Elle est généralement utilisée pour renforcer une dent
            endommagée ou pour améliorer l'apparence d'une dent déformée ou de
            couleur inesthétique.
          </SpaceP>
          <SpaceP>
            Les ponts et les couronnes peuvent être utilisés pour :
          </SpaceP>
          <OlList>
            <LiP>Remplacer une ou plusieurs dents manquantes</LiP>
            <LiP>Renforcer une dent endommagée</LiP>
            <LiP>
              Améliorer l'apparence d'une dent déformée ou de couleur
              inesthétique
            </LiP>
            <LiP>
              Maintenir l'espace et la forme des dents adjacentes aux dents
              manquantes
            </LiP>
            <LiP>Améliorer la mastication et la prononciation</LiP>
          </OlList>
          <h3>Dentier et Partiel</h3>
          <SpaceP>
            Un dentier est un appareil dentaire remplaçant une ou plusieurs
            dents manquantes. Il peut être complet, c'est-à-dire qu'il remplace
            l'ensemble des dents de la mâchoire supérieure ou inférieure, ou
            partiel, c'est-à-dire qu'il remplace seulement certaines dents
            manquantes.
          </SpaceP>
          <SpaceP>
            Un partiel est généralement utilisé lorsqu'il reste encore des dents
            naturelles dans la bouche. Il peut être fixé de différentes
            manières, comme à l'aide de crochets métalliques qui s'accrochent
            aux dents naturelles ou à l'aide de rétention à l'aide de résine.
          </SpaceP>
          <SpaceP>
            Le dentier est fabriqué sur mesure pour chaque patient en utilisant
            des empreintes de la bouche et des modèles de dents. Il est
            généralement en résine acrylique et doit être ajusté par un dentiste
            ou un denturologiste pour s'assurer qu'il s'adapte correctement à la
            bouche et qu'il est confortable à porter.
          </SpaceP>
        </HalfServiceWrapper>
        <HalfServiceWrapper>
          <ImageGrid>
            <figure>
              <ImageFit src={dentier} alt="dentier amovible" />
            </figure>
            <figure>
              <ImageFit src={partiel} alt="partiel amovible" />
            </figure>
            <figure>
              <ImageFit src={couronne} alt="couronne" />
            </figure>
            <figure>
              <ImageFit src={pont} alt="pont" />
            </figure>
          </ImageGrid>
        </HalfServiceWrapper>
      </ServiceWrapper>
      <Footer />
    </HelmetProvider>
  );
};
export default ServiceWeb;
