import styled, { css } from "styled-components";

export const Box = styled.div`
  /* margin-top: 5rem; */
  padding: 80px 10px;
  ${"" /* background: black; */}
  background:#008AD8;
  ${"" /* position: absolute; */}
  bottom: 0;
  width: 100%;

  @media (max-width: 3000px) {
    padding: 70px 30px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 3000px;
  margin: 0 6rem;
  /* background: red; */
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10;
`;

export const Row = styled.div`
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(185px, 1fr)); */
  grid-gap: 20px;
  @media (min-width: 300) {
    grid-template-columns: repeat(auto-fill, minmax(185, 1fr));
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  @media (min-width: 1500px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`;

export const FooterLink = styled.a`
  &:hover {
    font-weight: bold;
    transition: 200ms ease-in;
  }
  ${(p) =>
    p.phone
      ? css`
          display: inline;
          align-items: left;
          text-decoration: none;
          padding: 0.5rem 2.4rem;
          font-size: 2.2rem;
          ${"" /* color: white; */}
          color: white;
          font-weight: bold;
          @media (max-width: 768px) {
            font-size: 2.8rem;
          }
        `
      : css`
          color: #fff;
          margin-bottom: 20px;
          font-size: 18px;
          text-decoration: none;
        `}
`;

export const Heading = styled.p`
  font-size: 24px;
  color: #fff;
  margin-bottom: 40px;
  font-weight: bold;
`;

export const CopyrightStyles = styled.div`
  position: fixed;
  background-color: black;
  color: #9acd32;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  text-align: center;
`;
