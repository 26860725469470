import styled from "styled-components";

const ImageValue = styled.img`
  @media (max-width: 600px) {
    width: 110px;
    height: 110px;
  }
  @media (min-width: 601px) {
    width: 120px;
    height: 120px;
  }
`;

const Comment = styled.div`
  color: #ffffff;
  &:hover {
    transition: 0.2s ease-in-out;
    scale: 1.5;
    translate: 0;
  }
  @media (max-width: 600px) {
    font-size: 1.8rem;
    margin-bottom: 4px;
  }
  @media (min-width: 601px) {
    font-size: 1.2rem;
  }
`;

const HoverImage = (props) => {
  const { imageUrl, comment, nameImage } = props;

  return (
    <div>
      <ImageValue src={imageUrl} alt={nameImage} />
      <Comment>{comment}</Comment>
    </div>
  );
};
export default HoverImage;
