import Sidebar from "../assets/wrappers/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "./Footer";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

const H1 = styled.h1`
  margin: 2rem 0;
  text-align: center;
  font-size: 40px;
`;

const H2 = styled.h2`
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 13rem;
  margin-right: 8rem;
  text-align: center;
  padding: 0 30px;
  font-size: 18px;
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 3rem;
  }
`;

const LinkAccueil = styled(Link)`
  color: black;
  text-align: center;
  font-weight: bold;
  display: inline;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 2.4rem;
  font-size: 2rem;
  cursor: pointer;
  margin-bottom: 3rem;
  text-decoration: underline;
`;

const UrgenceWrapper = styled.div`
  margin-top: 2rem;
  display: grid;
  align-content: center;
  background-color: #f9f7f6;
`;

const ErrorPage404 = () => {
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Erreur 404 | Centre Santé Sourire</title>
          <meta name="robots" content="noindex" />
        </Helmet>
      </HelmetProvider>
      <Sidebar>
        <Navbar />
      </Sidebar>

      <UrgenceWrapper>
        <H1>Erreur 404</H1>
        <H2>Nous n'avons pas pu trouver la page que vous cherchez.</H2>
        <LinkAccueil to="/">Retour à la page d'accueil </LinkAccueil>
      </UrgenceWrapper>

      <Footer />
    </div>
  );
};
export default ErrorPage404;
