import styled from "styled-components";
import cliniqueExterieur from "../images/clinic/cliniqueExterieur614x409.avif";

const Header = styled.div`
  ${'' /* background-color: #54483a; */}
  grid-column: full-start / col-end 4;
  @media (max-width: 768px) {
    
    background-image: linear-gradient(
        rgba(0,138,216, 0.9),
        rgba(16, 29, 44, 0.9)
      )
  }

  @media (min-width: 769px) {
    
    background-image: linear-gradient(
        rgba(0,138,216, 0.9),
        rgba(16, 29, 44, 0.9)
      ),
      url(${cliniqueExterieur});
  }
  background-size: cover;
  
  padding: 8rem;

  display: grid;
  grid-template-rows: 1f r min-content minmax(6rem, min-content) 1fr;
  grid-template-columns: minmax(min-content, max-content);
  grid-row-gap: 1.5rem;
  justify-content: center;
  
  @media only screen and (max-width: 50em) {
    grid-column: 1/ -1;
  }
  @media only screen and (max-width: 768px) {
    padding: 5rem;
  }
  .header__logo {
    height: 8rem;
    justify-self: center;
  }
  ${"" /* Button */}

  ${'' /* .btn {
    background-color: #c69963;
    margin-bottom: 8rem;
    color: #fff;
    border: none;
    border-radius: 10;
    font-family: "Josefin Sans", sans-serif;
    font-size: 1.5rem;
    text-transform: uppercase;
    padding: 1.8rem 3rem;
    cursor: pointer;
    transition: all 0.2s;
  }
  .btn:hover {
    background-color: #b28451;
  }
  .header__btn {
    align-self: start;
    justify-self: start;
  }
   */}
`;
export default Header;
