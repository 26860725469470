import {CopyrightStyles} from "../assets/wrappers/FooterStyles";
import React from "react";

const Copyright = () => {
  const year = new Date().getFullYear();

  return <CopyrightStyles>{`Copyright © Les Centres Santé Sourire Inc. ${year}`}</CopyrightStyles>;
};

export default Copyright;
