import Sidebar from "../assets/wrappers/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "./Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";

import styled from "styled-components";

const H1 = styled.h1`
  margin: 2rem 0;
  text-align: center;
  font-size: 20px;
`;
const LiFont = styled.li`
  font-size: 16px;
`;

const H2 = styled.h2`
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 13rem;
  margin-right: 8rem;
  text-align: left;
  padding: 0 30px;
  font-size: 18px;
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 3rem;
  }
`;

const Exemple = styled.ul`
  margin: 0rem 14rem;
  text-align: left;
  padding: 0 60px;
  @media screen and (max-width: 768px) {
    margin: 0rem 4rem;
  }
`;

const UrgenceWrapper = styled.div`
  margin-top: 2rem;
  display: grid;
  align-content: center;
  background-color: #f9f7f6;
`;

const Phone = styled.a`
  color: #c1d300;
  margin-bottom: 20px;
  font-size: 16px;
  text-decoration: none;
  &:hover {
    font-weight: bold;
    transition: 200ms ease-in;
  }
`;

const Urgence = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Urgence Dentaire | Dentiste à Verdun 
        </title>
        <meta
          name="description"
          content="Besoin d'urgence dentaire ? Trouvez un dentiste disponible pour prendre en charge vos problèmes dentaires. Contactez-nous dès maintenant."
        />
      </Helmet>
      <Sidebar>
        <Navbar />
      </Sidebar>

      <UrgenceWrapper>
        <H1>URGENCES DENTAIRES</H1>
        <H2>
          Il existe différents types d'urgences dentaires qui nécessitent des
          soins immédiats. Voici quelques exemples:
        </H2>
        <Exemple>
          <LiFont>
            Douleur aiguë: une douleur intense qui peut être causée par une
            carie, une infection ou une fracture dentaire.
          </LiFont>
          <LiFont>
            Hémorragie de gencive: saignement des gencives qui peut être causé
            par une maladie parodontale ou un traitement dentaire récent.
          </LiFont>
          <LiFont>
            Douleur faciale: douleur ou gonflement des joues, de la mâchoire ou
            de la langue qui peut être causé par une infection dentaire ou une
            articulation temporo-mandibulaire bloquée.
          </LiFont>
          <LiFont>Fracture dentaire: une dent qui est brisée ou cassée.</LiFont>
          <LiFont>
            Douleur lors de la mastication: douleur ou inconfort lors de la
            mastication qui peut être causé par une carie ou une malocclusion
            dentaire.
          </LiFont>
        </Exemple>
        <H2>
          Si vous avez l'une de ces urgences dentaires, prenez rendez-vous avec
          nous au{" "}
          <Phone phone href="tel:514-767-4070">
            (514)-767-4070
          </Phone>
        </H2>
      </UrgenceWrapper>

      <Footer />
    </HelmetProvider>
  );
};
export default Urgence;
