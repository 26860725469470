import { React } from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import styled from "styled-components";
import { Box, Container, Row, Column } from "../assets/wrappers/FooterStyles";
import "../css/style.css";
import Copyright from "../components/Copyright";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { contentSecurityPolicy } from "helmet";

const FooterLink = styled.a`
  display: inline;
  align-items: left;
  text-decoration: none;
  color: white;
  @media (min-width: 769px) {
    font-size: 1.5rem;
    &:hover {
      font-weight: bold;
      transition: 200ms ease-in;
    }
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const FooterLink2 = styled(Link)`
  display: inline;
  align-items: left;
  text-decoration: none;

  color: white;
  @media (min-width: 769px) {
    font-size: 1.5rem;
    &:hover {
      font-weight: bold;
      transition: 200ms ease-in;
    }
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const UlAddress = styled.ul`
  list-style-type: none;
  margin-bottom: 10px;
`;

const PhoneDiv = styled.div`
  margin-top: 0px;
`;

const Heading = styled.p`
  color: #fff;
  font-weight: bold;
  color: greenyellow;

  @media (min-width: 769px) {
    font-size: 2.5rem;
    margin-bottom: 5px;
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 1px;
  }
`;
const LogoFooter = styled.li`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.4rem;
`;

const Logo = styled.span`
  color: #9acd32;
  font-weight: bold;
  font-size: 2.5rem;
  display: block;
  line-height: 1;
  padding-left: 0;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`;

const Logo2 = styled.span`
  color: #fff;
  font-weight: bold;
  font-size: 3rem;
  display: block;
  line-height: 1;
  padding-left: 0;
  margin-bottom: 0.5rem;
`;

const Logo3 = styled.span`
  color: #fff;
  font-weight: bold;
  font-size: 3.5rem;
  display: block;
  line-height: 1;
  padding-left: 0;
  /* margin-bottom: 5px; */
`;

const Footer = () => {
  const location = useLocation();
  useEffect(() => {
    const element = document.getElementById(location.hash.replace("#", ""));
    if (element) element.scrollIntoView();
  }, [location]);

  return (
    <Box>
      <Container>
        <Row>
          <Column>
            <FooterLink href="#">
              <ul>
                <LogoFooter>
                  <Logo>Centre</Logo>
                  <Logo2>Santé</Logo2>
                  <Logo3>Sourire</Logo3>
                </LogoFooter>
              </ul>
            </FooterLink>
          </Column>

          {/* <Column>
            <Heading>À propos</Heading>
            <FooterLink href="#">Notre vision</FooterLink>
            <FooterLink href="#">Équipe</FooterLink>
            <FooterLink href="#">Offre d'emploi</FooterLink>
            <FooterLink href="#">Financement disponible (Sous conditions)</FooterLink>

          </Column> */}
          <Column>
            <Heading>Services</Heading>

            <FooterLink2 to={{ pathname: "/service", hash: "#dg" }}>
              Dentisterie Générale
            </FooterLink2>
            <FooterLink2 to={{ pathname: "/service", hash: "#DP" }}>
              Dentisterie Pédiatrique
            </FooterLink2>
            <FooterLink2 to={{ pathname: "/service", hash: "#ORTHO" }}>
              Soins Orthodontiques
            </FooterLink2>
            <FooterLink2 to={{ pathname: "/service", hash: "#implant" }}>
              Service d'implantologie
            </FooterLink2>
            <FooterLink2 to={{ pathname: "/service", hash: "#PARO" }}>
              Service de Parodontie
            </FooterLink2>
          </Column>
          <Column>
            <Heading>Nous joindre</Heading>

            <FooterLink
              href="https://www.google.ca/maps/dir//5773+Avenue+Bannantyne,+Verdun,+QC+H4H+1H2/@45.4497831,-73.585815,15z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x4cc91059f74398c1:0x8db710fe1702358f!2m2!1d-73.5770603!2d45.4497834"
              title="Itinéraire vers la clinique"
              target="_blank"
            >
              <UlAddress>
                <li>5773 Avenue Bannantyne</li>
                <li>Verdun,Québec, H4H 1H2</li>
              </UlAddress>
            </FooterLink>
            <FooterLink href="mailto:info@santesourire.ca">
              <i className="fa-solid fa-envelope"></i>
              <span style={{ marginLeft: "10px" }}>info@santesourire.ca</span>
            </FooterLink>
            <PhoneDiv>
              <FooterLink href="tel:514-767-4070">
                <i className="fa-solid fa-phone"></i>
                <span style={{ marginLeft: "10px" }}>(514) 767-4070</span>
              </FooterLink>
            </PhoneDiv>
          </Column>
          <Column>
            <Heading>Réseaux Sociaux</Heading>
            <FooterLink href="https://www.facebook.com/people/Centre-Sant%C3%A9-Sourire/100064086003317/">
              <i className="fab fa-facebook-f"> </i>
              <span style={{ marginLeft: "14px" }}>Facebook</span>
            </FooterLink>
            <FooterLink href="https://www.instagram.com/centre_sante_sourire/">
              <i className="fab fa-instagram"></i>
              <span style={{ marginLeft: "10px" }}>Instagram</span>
            </FooterLink>
            <FooterLink href="https://twitter.com/spadentaire">
              <i className="fab fa-x-twitter"> </i>
              <span style={{ marginLeft: "10px" }}>Twitter</span>
            </FooterLink>
          </Column>
          <Copyright />
        </Row>
      </Container>
    </Box>
  );
};
export default Footer;
