import styled from "styled-components";
import RendezVous from "../pages/RendezVous";
import { Link } from "react-router-dom";

const H1 = styled.h1`
  margin: 1rem 0;
  text-align: center;
  font-size: 20px;
`;

const H2 = styled.h2`
  margin-top: 0rem;
  margin-bottom: 1rem;
  margin-left: 10rem;
  margin-right: 3rem;
  ${"" /* text-align: justify; */}
  text-align:center;
  padding: 0 20px;
  font-size: 18px;
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 3rem;
  }
`;
const HLink = styled.h2`
  ${"" /* margin-top: 0.5rem; */}
  margin-bottom: 2rem;
  margin-left: 10rem;
  margin-right: 3rem;
  ${"" /* text-align: justify; */}
  text-align:center;
  padding: 0 20px;
  font-size: 18px;
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 3rem;
  }
`;

const UrgenceWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: grid;
  align-content: center;
  background-color: #f9f7f6;
`;

const NewUpdates = () => {
  return (
    <div>
      <UrgenceWrapper>
        <H1>Cher(e) patient(e),</H1>
        <H2>
          Veuillez noter que notre clinique dentaire sera ouvert à partir de 11h
          mercredi 7 août 2024 en raison d'une panne de courant électrique. Nous
          vous prions de bien vouloir nous contacter plus tard dans la journée
          afin de convenir d'une nouvelle date pour votre rendez-vous.
        </H2>
        <H2>
          Nous vous remercions de votre compréhension et de votre collaboration
          pendant cette période.
        </H2>
        <H2>Cordialement,</H2>
        <H2>L'équipe de la clinique dentaire.</H2>
        <HLink>
          <Link to={{ pathname: "/rendezvous", hash: "#rv" }}>
            Pour nous laisser un message, veuillez cliquer ici.
          </Link>
        </HLink>

        <H1>Dear patient,</H1>
        <H2>
          Please note that our dental clinic will be open from 11:00 Wednesday August 7 2024, due to an
           power outage. We kindly request you to contact us to
          reschedule your appointment for a future date.
        </H2>
        <H2>
          Thank you for your understanding and cooperation during this time.
        </H2>
        <H2>Best regards,</H2>
        <H2>The dental clinic team.</H2>
        <HLink>
          <Link to={{ pathname: "/rendezvous", hash: "#rv" }}>
            To leave us a message, please click here.
          </Link>
        </HLink>
      </UrgenceWrapper>
    </div>
  );
};
export default NewUpdates;
