import styled from "styled-components";
import cliniqueExterieur from "../images/clinic/cliniqueExterieur614x409.avif";


// DMD
export const Csstors = styled.div`
  ${'' /* background-color: #008AD8; */}
  ${'' /* background-image: linear-gradient(to bottom,rgba(0,138,216, 0.9), rgba(0,138,216, 0.9)); */}
  background-image: linear-gradient(
      rgba(0,138,216, 0.9),
      rgba(16, 29, 44, 0.9)
    )
    ;
    background-size: cover;
  grid-column: col-start 5 / full-end;
  padding: 3rem;
  display: grid;
  align-content: center;
  justify-content: center;
  justify-items: center;
  grid-row-gap: 2rem;

  @media only screen and (max-width: 50em) {
    grid-column: 1 / -1;
  }
`;

export const CsstorsList = styled.div`
  display: grid;
  grid-template-columns: min-content max-content min-content max-content;
  grid-column-gap: 2rem;
  grid-row-gap: 5vh;
  align-items: center;

  @media only screen and (max-width: 1400px) {
    grid-template-columns: repeat(2, min-content max-content);
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: min-content max-content;
  }
`;
export const CsstorsImg = styled.img`
  width: 6rem;
  border-radius: 50%;
  display: block;
  margin-bottom: 5px;
  &:hover {
	scale:1.1;}
`;

export const JobTitle = styled.h3`
  /* text-transform: uppercase; */
  color: #aaa;
  margin-top: -3px;
  font-size: 1.4rem
`;

