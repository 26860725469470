import { useState, useEffect, useRef } from "react";
import {
  Nav,
  NavLink,
  Map,
  Bars,
  Phone,
  NavMenu,
  NavBtn,
  NavBtnLink,
  CloseBars,
} from "../assets/wrappers/MainNav.js";
import { FooterLink } from "../assets/wrappers/FooterStyles.js";
import Logo from "./Logo.js";

function Navbar() {
  const [openDrawer, toggleDrawer] = useState(false);
  const drawerRef = useRef(null);

  useEffect(() => {
    /* Close the drawer when the user clicks outside of it */
    const closeDrawer = (event) => {
      if (drawerRef.current && drawerRef.current.contains(event.target)) {
        return;
      }

      toggleDrawer(false);
    };

    document.addEventListener("mousedown", closeDrawer);
    return () => document.removeEventListener("mousedown", closeDrawer);
  }, []);

  return (
    <Nav>
      <Logo />
      <a href={`tel:${5147674070}`} aria-label="Phone">
        <Phone />
      </a>
      <a href="https://www.google.ca/maps/dir//5773+Avenue+Bannantyne,+Verdun,+QC+H4H+1H2/@45.4497776,-73.5792482,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4cc91059f74398c1:0x8db710fe1702358f!2m2!1d-73.5770595!2d45.4497776" aria-label="Map">
        <Map />
      </a>
      <Bars onClick={() => toggleDrawer(true)} />

      <NavMenu ref={drawerRef} openDrawer={openDrawer}>
        <CloseBars onClick={() => toggleDrawer(false)} />
        <NavLink to="/urgence">Urgence</NavLink>
        <NavLink to="/nosdentistes">Notre équipe</NavLink>
        <NavLink to="/service">Nos services</NavLink>
        {/* <NavLink to="/emploi">Offre d'emploi</NavLink> */}
        {/* <NavLink to="/clinique">La clinique</NavLink> */}
        <FooterLink phone href="tel:514-767-4070">
          (514)-767-4070
        </FooterLink>
        <NavBtn>
          <NavBtnLink to="/rendezvous">RENDEZ-VOUS EN LIGNE</NavBtnLink>
        </NavBtn>
      </NavMenu>
    </Nav>
  );
}
export default Navbar;
