import styled from "styled-components";

const Sidebar = styled.div`
  position: sticky;
  top: 0px;
  z-index: 100;
  @media only screen and (max-width: 3000px) {
   
  .sidebarLogo {
    max-height: 100px;
    padding: 0.2rem 0.2rem;
    border:solid;
    border-color: #008AD8;
    border-width:0.2rem;
  }}
`;
export default Sidebar;
