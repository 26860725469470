import React from "react";
import styled from "styled-components";
import { Block } from "../assets/wrappers/Typography";
import { MemberDmd } from "../assets/wrappers/DmdProfileStyle";

const ContainerDmdProfileImage = styled.div`
  align-items: center;
  text-decoration: none;
`;

const DmdProfileStyle = styled.div`
  display: grid;
  background-image: linear-gradient(to right, #1E4361, #7bbaed);
  align-content: left;
  background-color: #f9f7f6;
  margin: 20px 20px 20px 10px;

  @media only screen and (max-width: 3000px) {
    grid-template-columns: 1fr 3fr;

    justify-content: space-between;
  }
  @media only screen and (max-width: 768px) {
    ${'' /* grid-template-columns: 1fr; */}
    justify-content: space-between;
  }
`;

const Contentdmd = styled.div`
  grid-column: 2/ 4;
  justify-content: left;
  justify-items: left;
  overflow: hidden;
  padding-top: 3rem;

  h1 {
    color: white;
  }
`;

const ImageDmd = styled.img`
  display: block;
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;

  @media only screen and (min-width: 769px) {
    width: 30rem;
    height: 30rem;
    padding: 3rem;
  }
  @media only screen and (max-width: 768px) {
    width: 20rem;
    height: 20rem;
    padding: 1rem;
  }
`;
const Pmembre = styled.p`
  margin-top: 20 px;
  margin-bottom: 20px;
  padding-bottom: 20 px;
`;

const Sentence = styled.p`
  font-size: 13px;
  line-height: 1.8;
  color: white; 
  @media only screen and (max-width: 768px) {
    font-size: 10px;
    line-height: 1.5;
  }
`;

const DentistProfile = (props) => {
  return (
    <DmdProfileStyle>
      <ContainerDmdProfileImage>
        <ImageDmd dmdImage src={props.dmdPic} dmd alt={"Profil dmd"} />
      </ContainerDmdProfileImage>
      <Contentdmd>
        <h1>{props.name}</h1>
        <Block>
          <h2>
            <span>{props.titre}</span>
          </h2>
        </Block>
        <h2>{props.title}</h2>

        <Sentence>
          <h4>{props.description1}</h4>
          <h4>{props.description2}</h4>
          <h4>{props.description3}</h4>
          <h4>{props.description4}</h4>
          <h4>{props.description5}</h4>
          <h4>{props.description6}</h4>

          <h2>{props.title2}</h2>
          <h4>{props.exp1}</h4>
          <h4>{props.exp2}</h4>
          <h4>{props.exp3}</h4>
          <h4>{props.exp4}</h4>
          <h4>{props.exp5}</h4>
          <h4>{props.exp6}</h4>
        </Sentence>
        {/* <Pmembre>
        
          <h2>MEMBRE DES ASSOCIATIONS SUIVANTES</h2>
          <ImageContainer>
Pmembre
          <MemberDmd src={props.membre} alt="Membre association dentaire" />
          <MemberDmd src={props.membre2} alt="Membre association dentaire" />
          </ImageContainer>
        </Pmembre> */}
      </Contentdmd>
    </DmdProfileStyle>
  );
};
export default DentistProfile;
