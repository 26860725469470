import styled from "styled-components";


const Card = styled.div`
 
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr */
  /* column-gap: 1em; */
  /* row-gap:1em */
  margin:auto;
  margin-top: 10px;
  @media (max-width: 600px) {
    margin: auto; 
  }
   
  
  
`;
export default Card;
