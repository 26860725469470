import "../css/style.css"
import entre1 from "../assets/images/clinic/entre-1-1024x634.avif";
import entre2 from "../assets/images/clinic/entre-2-1024x683.avif";
import entre3 from "../assets/images/clinic/entre-3-1024x683.avif";
import entre4 from "../assets/images/clinic/entre-4-1024x683.avif";
import entre5 from "../assets/images/clinic/entre-5-attente-1024x584.avif";
import entre6 from "../assets/images/clinic/entre-6-attente-1024x627.avif";
import entre7 from "../assets/images/clinic/entre-7-1024x683.avif";
import entre8 from "../assets/images/clinic/entre-8-exterieur-1024x683.avif";
import entre9 from "../assets/images/clinic/entre-9-683x1024.avif";
import entre10 from "../assets/images/clinic/entre-10-traitement-1024x623.avif";
import entre11 from "../assets/images/clinic/entre-11-traitement-1024x725.avif";
import entre12 from "../assets/images/clinic/entre-12-traitement-et-couloir-1024x683.avif";
import entre13 from "../assets/images/clinic/entre-13-jeux-1024x548.avif";
import entre14 from "../assets/images/clinic/cliniqueExterieur614x409.avif";
import styled from "styled-components";


const GallerySection = styled.section`

background-color: #bde0fe;
grid-column: full-start / full-end;
display: grid;
grid-template-columns: repeat(8, 1fr);
grid-template-rows: repeat(7, 5vw);
grid-gap: 1.5rem;
padding: 1.5rem;
`


const Gallery = () => {
  return (
    <GallerySection id="view-inside" >
      <figure className="gallery__item gallery__item--1">
        <img
          src={entre1}
          alt="Salle entre 1"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item--2">
        <img
          src={entre2}
          alt="Salle entre 2"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item--3">
        <img
          src={entre3}
          alt="Salle entre 3"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item--4">
        <img
          src={entre4}
          alt="Salle entre 4"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item--5">
        <img
          src={entre5}
          alt="Salle entre 5"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item--6">
        <img
          src={entre6}
          alt="Salle entre 6"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item--7">
        <img
          src={entre7}
          alt="Salle entre 7"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item--8">
        <img
          src={entre8}
          alt="Salle entre 8"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item--9">
        <img
          src={entre9}
          alt="Salle entre 9"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item--10">
        <img
          src={entre10}
          alt="Salle entre 10"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item--11">
        <img
          src={entre11}
          alt="Salle entre 11"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item--12">
        <img
          src={entre12}
          alt="Salle entre 12"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item--13">
        <img
          src={entre13}
          alt="Salle entre 13"
          className="gallery__img"
        />
      </figure>

      <figure className="gallery__item gallery__item--14">
        <img
          src={entre14}
          alt="Salle entre 14"
          className="gallery__img"
        />
      </figure>
    </GallerySection>
  )
}
export default Gallery