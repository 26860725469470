import implant from "../assets/images/service/implant.avif";
import ppf from "../assets/images/service/ppf.avif";
import greffe from "../assets/images/service/greffeGencive.avif";
import traitementCanal from "../assets/images/service/traitementcanal.avif";
import nettoyage from "../assets/images/service/nettoyage.avif";
import pedo from "../assets/images/service/pediatric.avif";
import ortho from "../assets/images/service/ortho_icon.avif";
import perio from "../assets/images/service/perio.avif";
import denture from "../assets/images/service/denture.avif";
import bleach from "../assets/images/service/blanchiment.avif";
import "../css/style.css";
import Card from "../assets/wrappers/Card";
import styled from "styled-components";

const Features = styled.div`
  grid-column: center-start / center-end;
  margin: 4rem 4rem;

  display: grid;
  @media (max-width: 768px) {
    display: block;
  }
  grid-template-columns: repeat(auto-fit, minmax(25rem, 2fr));

  align-items: start;
`;
const ServiceTitre = styled.h1`
  font-size: 4.5rem;
  color: #76cf44;
  line-height: 1;
  text-align: center;
`;
const IconTextWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-row-gap: 1.5rem;
  grid-column-gap: 2.5rem;
  margin-top: 10px;
`;
const HService = styled.h2`
  font-size: 20px;
  font-size: 1.9rem;
  color: rgb(0,138,216);
`;

const ImgIcon = styled.img`
  width: 6rem;
  height: 4.5rem;
  grid-row: 1 / span 2;
  transform: translateY(-1rem);
  fill: #c69963;
`;

const ServicesMainPage = () => {
  return (
    <div>
      <ServiceTitre>Services Dentaires</ServiceTitre>
      <Features>
        <Card>
          <div>
            {/* Examen complet nettoyage */}
            <IconTextWrapper>
              <ImgIcon src={nettoyage} alt="nettoyage" />

              <HService>Examen complet et Nettoyage</HService>
            </IconTextWrapper>

            {/* Dentisterie perdiatrique */}
            <IconTextWrapper>
              <ImgIcon src={pedo} alt="pedo" />

              <HService>Dentisterie Pédiatrique</HService>
            </IconTextWrapper>
            {/* Orthodontie */}
            <IconTextWrapper>
              <ImgIcon src={ortho} alt="ortho" />

              <HService>Orthodontie</HService>
            </IconTextWrapper>

            {/* parodontie */}
            <IconTextWrapper>
              <ImgIcon src={perio} alt="perio" />

              <HService>Service de Parodontie</HService>
            </IconTextWrapper>
          </div>
        </Card>

        <Card>
          {/* Implantologie */}
          <IconTextWrapper>
            <ImgIcon src={implant} alt="Implant" />

            <HService>Service d'Implantologie</HService>
          </IconTextWrapper>
          {/* Greffe gencive */}
          <IconTextWrapper>
            <ImgIcon src={greffe} alt="greffe" />
            <HService>Greffe de Gencive</HService>
          </IconTextWrapper>

          {/* traitement canal */}
          <IconTextWrapper>
            <ImgIcon src={traitementCanal} alt="endo" />
            <HService>Traitement de canal</HService>
          </IconTextWrapper>
          {/* PPF */}
          <IconTextWrapper>
            <ImgIcon src={ppf} alt="pontCouronne" />
            <HService>Pont, couronne, facette</HService>
          </IconTextWrapper>
        </Card>
        <Card>
          {/* PPA */}
          <IconTextWrapper>
            <ImgIcon src={denture} alt="dentier" />
            <HService>Dentier et partiel</HService>
          </IconTextWrapper>

          {/* Blanchiment */}

          <IconTextWrapper>
            <ImgIcon src={bleach} alt="blanchiment" />
            <HService>Blanchiment</HService>
          </IconTextWrapper>
        </Card>
      </Features>
    </div>
  );
};
export default ServicesMainPage;
