import React from "react";
import styled from "styled-components";

const ContainerFormMap = styled.div`
 
  ${'' /* align-content: center; */}
  
  
  @media only screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    display: grid;
  
  
  }
  @media only screen and (max-width: 200px) {
    grid-template-columns: 1fr;
  
  }
`;
export default ContainerFormMap;
