import styled from "styled-components";

const Frame = styled.iframe`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 600px; /* Set the default width */
  height: 600px; /* Set the default height */

  @media only screen and (max-width: 400px) {
    width: 350px; /* Update width for smaller screens */
    height: 300px; /* Update height for smaller screens */
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Set the container height to full viewport height */
`;

const MapsV3 = () => {
  return (
    <Container>
      <Frame
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.9934978568185!2d-73.57924898446099!3d45.449787142426125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc91059f76b87e5%3A0xf934f3980171d7bc!2sLes%20Centres%20Sant%C3%A9%20Sourire%20inc.!5e0!3m2!1sen!2sca!4v1664072694046!5m2!1sen!2sca"
      ></Frame>
    </Container>
  );
};

export default MapsV3;
