import styled from "styled-components";
import { css } from "styled-components";

const Container = styled.div`
  
  ${(p) =>
    p.dmdStyle
      ? css``
      : css`
          color: #fff;
          margin-bottom: 20px;
          font-size: 18px;
          text-decoration: none;
        `}

  @media only screen and (max-width: 3000px) {
    /* grid-template-rows: repeat(6, 6fr); */
    ${"" /* grid-template-columns: repeat(6,6fr); */}
    display: grid;
    grid-template-columns: [full-start] minmax(6rem, 1fr) [center-start] repeat(
        8,
        [col-start] minmax(min-content, 14rem) [col-end]
      ) [center-end] minmax(6rem, 1fr);
  }
  @media only screen and (max-width: 600px) {
    /* grid-template-rows: repeat(6, 6fr); */
    grid-template-columns: 1fr;
  }
`;
export default Container;

// /grid-column: col-start 5 / full-end;/
