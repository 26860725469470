import "../css/style.css";

import React from "react";
import styled from "styled-components";

// import ServiceWrapper from "../assets/wrappers/ServiceWrapper";

const ServiceWrapper = styled.div`
  display: grid;
  align-content: center;
  background-color: #f9f7f6;
  padding: 1.5rem 3rem;
  margin: 0 10px;

  @media only screen and (min-width: 501px) {
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
  }
  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    ${"" /* justify-content: space-between; */}
  }
`;
const AddressScheduleContainer = styled.div`
  /* margin: auto; */
  padding: 1rem;
  @media screen and (max-width: 768px) {
    padding: 0.3rem;
  }
`;
const Heading2 = styled.h2`

  font-size: 3rem;
  line-height: 1;
  /* color: #54483a; */
  color: rgb(154, 205, 50);
  ${"" /* margin-bottom: 3rem; */}
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
  ${"" /* text-align: center; */}
`;

const Heading5 = styled.h2`
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  font-size: 2.8rem;
  color: rgb(255, 165, 0);
  margin-bottom: 2rem;
  max-width: 1600px;
  ${"" /* text-align: center; */}
`;

const MessageImportant = styled.h1`
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
  font-size: 3rem;
  color: #000;
  margin-top: 4rem;
  ${"" /* text-align: center; */}
`;

const AddresHoraireContainer = styled.div`
  ${"" /* grid-column: col-start 5 / full-end; */}
  padding: 6rem 8vw;
  display: grid;
  justify-content: center;
  ${"" /* justify-items: center; */}
  ${"" /* margin-top: 8rem; */}
`;

const ScheduleContainer = styled.div`
  font-size: 1.5rem;
  font-style: italic;
  color: black;
  margin-bottom: 0;
`;

const WeekContainer = styled.tbody`
  line-height: 2.5;
  font-size: 1.5rem;
  display: grid;
`;

const DayContainer = styled.tr`
  border-bottom: 0.2rem dotted #9acd32;

  &:nth-child(odd) {
    background-color: #f8f9fa;
  }

  &:nth-child(even) {
    background-color: #e9ecef;
  }
`;

const DayName = styled.th`
  width: 174px;
  text-align: left;
  margin: 0 0rem 0 6rem;

  padding-left: 1rem;
  padding-right: 1rem;
`;
const HourContainer = styled.td`
  padding-right: 1rem;
  padding-left: 1.2rem;
`;

const Horaire = () => {
  return (
    <ServiceWrapper>
      <AddressScheduleContainer>
        <MessageImportant>IMPORTANT</MessageImportant>
        <Heading5>
          Merci de nous avertir au minimum 48h à l'avance si vous ne pouvez pas
          vous présenter à votre rendez-vous.
        </Heading5>
        {/* <Heading6>Adresse</Heading6>
        <Heading3>5773 Bannantyne</Heading3>
        <Heading3>Verdun, Québec</Heading3>
        <Heading3>H4H 1H2</Heading3>
        <HeadingPhone>(514)-767-4070</HeadingPhone> */}
      </AddressScheduleContainer>

      <div>
        <AddresHoraireContainer>
          <Heading2>Horaire</Heading2>
          <ScheduleContainer>
            <table>
              <WeekContainer>
                <DayContainer>
                  <DayName>Lundi</DayName>
                  <HourContainer>7:00-19:00</HourContainer>
                  {/* <Comment className="app__day1" >exception</Comment> */}
                </DayContainer>

                <DayContainer>
                  <DayName>Mardi</DayName>
                  <HourContainer>7:00-19:00</HourContainer>
                </DayContainer>

                <DayContainer>
                  <DayName>Mercredi</DayName>
                  <HourContainer>7:00-19:00</HourContainer>
                </DayContainer>

                <DayContainer>
                  <DayName>Jeudi</DayName>
                  <HourContainer>7:00-18:00</HourContainer>
                </DayContainer>

                <DayContainer>
                  <DayName>Vendredi</DayName>
                  <HourContainer>8:00-17:00</HourContainer>
                </DayContainer>

                <DayContainer>
                  <DayName>Samedi</DayName>
                  <HourContainer>Fermé</HourContainer>
                </DayContainer>

                <DayContainer>
                  <DayName>Dimanche</DayName>
                  <HourContainer>Fermé</HourContainer>
                </DayContainer>
              </WeekContainer>
            </table>
          </ScheduleContainer>
          
        </AddresHoraireContainer>
      </div>
    </ServiceWrapper>
  );
};
export default Horaire;
