import styled from "styled-components";
import Sidebar from "../assets/wrappers/Sidebar";
import Navbar from "../components/Navbar";
import Container from "../assets/wrappers/Container";
import Header from "../assets/wrappers/Header";
import ValeurClinique from "../components/ValeurClinique";
import Dentist from "../components/Dentist";
import Services from "./ServicesMainPage";
// import Administration from "./Administration";
// import { Salles } from "./Salles";
import Gallery from "./Gallery";
import Footer from "./Footer";
import Horaire from "../components/Horaire";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NewUpdates from "../components/NewUpdates";

const Heading1 = styled.h1`
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  /* font-size: 4.5rem;*/ /* Set a default font size */
  color: white;
  line-height: 1;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 3rem;
  }
    @media (min-width: 768px) {
      font-size: 4.5rem;
    }
  
`;

const Valeur = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 1.5rem;
  align-items: center;
  font-size: 2rem;
  color: white;

  &::before,
  &::after {
    content: "";
    height: 1px;
    display: block;
    background-color: currentColor;
  }
`;

const MainPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title> Centre Santé Sourire | Dentiste à Verdun, Montréal </title>
        <meta
          name="description"
          content="Des dentistes de confiance à Verdun pour des soins dentaires complets. Visitez notre clinique dès aujourd'hui pour un sourire éclatant !"
        />
      </Helmet>
      <Sidebar>
        <Navbar />
      </Sidebar>
      {/* <NewUpdates/> */}
      <Container>
        <Header>
          <Heading1>Vos dentistes à Verdun depuis plus de 30 ans</Heading1>
          <Valeur>Nos valeurs</Valeur>
          <ValeurClinique />
        </Header>
        <Dentist />
      </Container>
      <Services />
      <Horaire />

      <Gallery />

      <Footer />
    </HelmetProvider>
  );
};
export default MainPage;
