import reneOu from "../assets/images/dentist/dmdMainPage/reneOu.webp";
import duyVu from "../assets/images/dentist/dmdMainPage/duyVu.webp";
import marwanHbeika from "../assets/images/dentist/dmdMainPage/marwanHbeika.webp";
import elaineLarochelle from "../assets/images/dentist/dmdMainPage/elaineLarochelle.webp";
import kalinaPluta from "../assets/images/dentist/dmdMainPage/kalinaPluta.webp";
import nathalielarouche from "../assets/images/dentist/dmdMainPage/nathalielarouche.webp";
import roxannevallee from "../assets/images/dentist/dmdMainPage/roxannevallee.webp";
// import femaleProfile from "../assets/images/dentist/dmdMainPage/femaleProfile.webp";
import ffPic from "../assets/images/dentist/dmdMainPage/dre-florence-fleury.webp";
import alainNohra from "../assets/images/dentist/dmdMainPage/alainnohra.webp";
import saPic from "../assets/images/dentist/dmdMainPage/stephanie.webp";

import {
  Csstors,
  CsstorsList,
  CsstorsImg,
  JobTitle,
} from "../assets/wrappers/Csstor";

import styled from "styled-components";
import { Link } from "react-router-dom";

const sharedStyles = `
  font-family: "Josefin Sans", sans-serif;
  
`;

const Heading3 = styled.h1`
  font-size: 1.95rem;
  color: white;
  font-weight: bold;
  margin-right:5rem;
  ${sharedStyles}
`;

const Heading4 = styled.h2`
  font-size: 1.9rem;
  color: #f9f7f6;
  font-weight: 400;
  ${sharedStyles}
`;

const Dentist = () => {
  return (
    <Csstors>
      <div>
        <Heading3>Notre équipe</Heading3>
      </div>
      <CsstorsList>
        <Link to={{ pathname: "/nosdentistes", hash: "#dr-rene-ou" }}>
          <CsstorsImg src={reneOu} alt="dr-rene-ou" />{" "}
        </Link>
        <div>
          <Heading4>Dr René Ou</Heading4>
          <JobTitle>Dentiste Généraliste</JobTitle>
        </div>
        <Link to={{ pathname: "/nosdentistes", hash: "#dr-marwan-hbeika" }}>
          <CsstorsImg src={marwanHbeika} alt="dr-marwan-hbeika" />
        </Link>
        <div>
          <Heading4>Dr Marwan Hbeika</Heading4>
          <JobTitle>Dentiste Généraliste</JobTitle>
        </div>
        <Link to={{ pathname: "/nosdentistes", hash: "#dr-duy-dat-vu" }}>
          <CsstorsImg src={duyVu} alt="dr-duy-dat-vu" />
        </Link>
        <div>
          <Heading4>Dr Duy Dat Vu</Heading4>
          <JobTitle>Dentiste Pédiatrique</JobTitle>
        </div>

        <Link
          to={{ pathname: "/nosdentistes", hash: "#dre-nathalie-larouche" }}
        >
          <CsstorsImg src={nathalielarouche} alt="dre-nathalie-larouche" />
        </Link>
        <div>
          <Heading4>Dre Nathalie Larouche</Heading4>
          <JobTitle>Dentiste Généraliste</JobTitle>
        </div>
        <Link to={{ pathname: "/nosdentistes", hash: "#dre-roxanne-vallee" }}>
          <CsstorsImg src={roxannevallee} alt="dre-roxanne-vallee" />
        </Link>
        <div>
          <Heading4>Dre Roxane Vallée</Heading4>
          <JobTitle>Dentiste Généraliste</JobTitle>
        </div>
        <Link to={{ pathname: "/nosdentistes", hash: "#dr-elaine-larochelle" }}>
          <CsstorsImg src={elaineLarochelle} alt="dr-elaine-larochelle" />
        </Link>
        <div>
          <Heading4>Dre Elaine Larochelle</Heading4>
          <JobTitle>Dentiste Pédiatrique</JobTitle>
        </div>
        <Link to={{ pathname: "/nosdentistes", hash: "#dre-kalina-pluta" }}>
          <CsstorsImg src={kalinaPluta} alt="dre-kalina-pluta" />
        </Link>
        <div>
          <Heading4>Dre Kalina Pluta</Heading4>
          <JobTitle>Orthodontiste</JobTitle>
        </div>
        <Link
          to={{ pathname: "/nosdentistes", hash: "#dr-stephanie-allardPuscas" }}
        >
          <CsstorsImg src={saPic} alt="dr-stephanie-allardPuscas" />
        </Link>
        <div>
          <Heading4>Dre Stéphanie Allard-Puscas</Heading4>
          <JobTitle>Dentiste Généraliste</JobTitle>
        </div>
        <Link to={{ pathname: "/nosdentistes", hash: "#dr-fleury-amann" }}>
          <CsstorsImg src={ffPic} alt="dr-fleury-amann" />
        </Link>
        <div>
          <Heading4>Dre Florence Fleury Amann</Heading4>
          <JobTitle>Dentiste Généraliste</JobTitle>
        </div>
        <Link to={{ pathname: "/nosdentistes", hash: "#alain-nohra" }}>
          <CsstorsImg src={alainNohra} alt="alain-nohra" />
        </Link>
        <div>
          <Heading4>Alain Nohra</Heading4>
          <JobTitle>Denturologiste</JobTitle>
        </div>

        {/* <CsstorsImg src={femaleProfile} alt="nathalie-laforte" /> */}
        {/* <div>
          <Heading4>Nathalie Laforte</Heading4>
          <JobTitle>Directrice</JobTitle>
        </div> */}
      </CsstorsList>
    </Csstors>
  );
};

export default Dentist;
