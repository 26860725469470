import HoverImage from "./HoverImage";
import React from "react";

import styled from "styled-components";

const ValeurLogos = styled.div`
  display: grid;
  @media (max-width:1400px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 5rem;
   
    
  }

  @media (min-width: 1401px) {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 3rem;
    justify-content: center;
    align-items: center;
  }
`;

const ValeurClinique = () => {
  function getImagePath(name) {
    if (window.innerWidth <= 600) {
      return require(`../assets/images/values/mobile_webP/${name}110.webp`);
    }
    return require(`../assets/images/values/desktop_webP/${name}.webp`);
  }

  const patientCare = getImagePath("patientCare");
  const professionalisme = getImagePath("professionalisme");
  const safety = getImagePath("safety");
  const accessibility = getImagePath("accessibility");
  return (
    <div>
      <ValeurLogos>
        <HoverImage imageUrl={patientCare} comment="Soins personnalisés" nameImage="Soin-patients"/>
        <HoverImage imageUrl={professionalisme} comment="Professionnalisme" nameImage=" Soin-professionnel" />
        <HoverImage imageUrl={safety} comment="Lieu Sécuritaire" nameImage="Lieu-Securitaire" />
        <HoverImage imageUrl={accessibility} comment="Accessible" nameImage="Accessible-fauteuil-roulant" />
      </ValeurLogos>
    </div>
  );
};
export default ValeurClinique;
