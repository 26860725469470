import "../css/wrapperForm.css";
import Spinner from "./Spinner";
import { useState, useEffect } from "react";
import ContainerFormMap from "../assets/wrappers/ContainerFormMap";
import "../css/style.css";
import styled from "styled-components";
import React from "react";
import db from "../firebase";
import ReCAPTCHA from "react-google-recaptcha";
import MapsV3 from "./MapsV3";
import DownloadLink from "./DownloadQuestionnaire";

const FormS = styled.form`
  column-gap: 3.2rem;
  row-gap: 2.4rem;
  background-color: #f9f7f6;
  padding: 6rem 2rem;
  justify-content: center;
  justify-items: center;
  text-transform: uppercase;
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-left: 4rem;
  display: grid;

  @media only screen and (min-width: 501px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    justify-content: space-between;
  }
`;

const Columns2 = styled.div``;

const OptionDentiste = styled.div`
  display: block;
`;

const InputLabel = styled.input`
  width: 250px;
  padding: 1.8rem;
  font-size: 1.2rem;
  font-family: inherit;
  color: white;
  border: none;
  background-color: #1a73e8; //  #9acd32
  border-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  display: block;
  margin: 1rem;
  height: 42px;
  ::placeholder {
    color: white;
  }
`;

const SelectLabel = styled.select`
  width: 250px;
  padding: 1.2rem;
  font-size: 1.2rem;
  font-family: inherit;
  /* color: inherit; */
  border: none;
  background-color: #1a73e8; //#9acd32
  border-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  color: white;
  display: block;
  margin: 1rem;
  height: 42px;
`;

const ButtonEnvoyer = styled.button`
  color: white;
  border-radius: 10px;
  background: #008ad8;
`;

const InputAutre = styled.input`
  display: flex;
  margin: 1rem;
  margin-top: 10px;
  width: 250px;
  padding: 1.2rem;
  font-size: 1.2rem;
  font-family: inherit;
  color: white;
  border: none;
  background-color: #1a73e8; //#9acd32
  border-radius: 9px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  ::placeholder {
    color: white;
  }

  /* your input styles here */
`;
const EmailLink = styled.a`
  color: blue; // Set the color of the link
  text-decoration: underline; // Add underline to indicate it's clickable

  &:hover {
    color: darkblue; // Change color on hover
  }
`;

const DentisteOptions = {
  pediatrique: [
    "Dr Duy Dat Vu",
    "Dre Elaine Larochelle",
    "Premier dentiste diponible",
  ],
  generaliste: [
    "Dr René Ou",
    "Dr Marwan Hbeika",
    "Dre Nathalie Larouche",
    "Dre Roxane Vallée",
    "Dre Stéphanie Allard-Puscas",
    "Dre Florence Fleury Amann",
    "Aucun dentiste en particulier",
  ],
};

let timeout;

export default function FormEachEmailDentistV2() {
  const [loading, setLoading] = useState(false);
  const [nom, setNom] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [info, setInfo] = useState("");
  const [option, setOption] = useState("");
  const [dentiste, setDentiste] = useState("");

  const [loader, setLoader] = useState(false);
  const [cap, setCap] = useState(true);

  const [dentisteType, setDentisteType] = useState("");

  const handleDentisteTypeChange = (e) => {
    setDentisteType(e.target.value);
    setDentiste("");
  };

  const handleDentisteChange = (e) => {
    setDentiste(e.target.value);
  };

  const dentisteOptions =
    dentisteType === "pediatrique"
      ? DentisteOptions.pediatrique
      : DentisteOptions.generaliste;

  let timeout;

  const dentistEmails = {
    "Dr Duy Dat Vu": "dent.pediatrique@santesourire.ca",
    "Dre Elaine Larochelle": "dent.pediatrique@santesourire.ca",
    "Dr René Ou": "dr.ou@santesourire.ca",
    "Dr Marwan Hbeika": "dr.hbeika@santesourire.ca",
    "Dre Nathalie Larouche": "dre.larouche@santesourire.ca",
    "Dre Roxane Vallée": "dre.vallee@santesourire.ca",
    "Dre Stéphanie Allard-Puscas": "dre.allardpuscas@santesourire.ca",
    "Dre Florence Fleury Amann": "dre.fleuryamann@santesourire.ca",
    "Aucun dentiste en particulier": "dr.ou@santesourire.ca",
    "Premier dentiste diponible": "dent.pediatrique@santesourire.ca",
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setLoader(true);
    timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    const selectedDentistEmail = dentistEmails[dentiste];
    // const patientEmail = [email];
    /*  const linkQuestionnaire =
      "https://firebasestorage.googleapis.com/v0/b/csstesting-d79f0.appspot.com/o/questionnaire-medical.pdf?alt=media&token=733b0888-f067-45d1-b1b5-c373fa3dbaa4";
 */
    // Firebase database if patient is not already in the system:

    /* if (option !== "Déjà patient de la clinique" && option !== "dentiste pediatrique")
     {
      db.collection("contacts").add({
        to: `${patientEmail}`,

        message: {
          subject: `Bienvenue au Centre Santé Sourire`,
          html: ` 
                 
                  
Cher(e) ${nom},<br><br>



&emsp;Au nom de toute l'équipe du Centre Santé Sourire, je tiens à vous souhaiter la plus chaleureuse des bienvenues ! Nous sommes ravis de vous accueillir dans notre clinique dentaire et nous sommes impatients de prendre soin de votre santé bucco-dentaire.<br><br>

&emsp;Votre confort et votre satisfaction sont notre priorité absolue. Afin de mieux vous connaître et de personnaliser au mieux votre expérience avec nous, nous vous prions de bien vouloir télécharger le questionnaire de santé en 
suivant ce lien <a href= "https://firebasestorage.googleapis.com/v0/b/csstesting-d79f0.appspot.com/o/questionnaire-medical.pdf?alt=media&token=733b0888-f067-45d1-b1b5-c373fa3dbaa4">Questionnaire médicale</a>. 
Une fois rempli, veuillez le renvoyer à notre adresse e-mail dédiée : <a href="mailto:${selectedDentistEmail}"> ${selectedDentistEmail}</a>.<br><br>

&emsp;Votre bien-être et votre santé sont entre de bonnes mains, et nous sommes là pour répondre à tous vos besoins dentaires avec professionnalisme et attention. N'hésitez pas à nous contacter pour toute question ou préoccupation.<br><br>

&emsp;Encore une fois, bienvenue au Centre Santé Sourire ! Nous sommes impatients de vous rencontrer et de vous offrir le sourire que vous méritez.<br><br>

Cordialement,<br><br>

L'équipe du Centre Santé Sourire<br>
514-767-4070<br>
www.centresantesourire.com<br>`,
        },
      });
    } */

    // Firebase database + Sendgrid
    db.collection("contacts")
      .add({
        // Data base only
        // name:nom,
        // telephone:phone,
        // email:phone,
        // info:info,

        to: `rt9games@gmail.com,${selectedDentistEmail}`,

        // to: `rt9games@gmail.com,`,

        message: {
          subject: `Votre patient(e) ${nom} vient de communiquer sur le site web Centre Santé Sourire`,
          html: ` 
                  Nom: ${nom}<br>
                  Email de: ${email}<br>
                  Numéro de téléphone: ${phone}<br>
                  Référence: ${option}<br>
                  Specialité: ${dentisteType}<br>
                  Dentiste référé(e): ${dentiste}<br>
                  Demande: <br>
                  &emsp;&emsp;&emsp;&emsp; ${info}`,
        },
      })
      .then(() => {
        alert("Nous vous contacterons dans les plus bref délais 👍");
        setLoader(false);
      })
      .catch((error) => {
        alert(error.message);
        setLoader(false);
      });

    setNom("");
    setEmail("");
    setPhone("");
    setInfo("");
    setOption("");
    setDentiste("");
  };

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const handleOnChange = () => {
    setCap(false);
  };

  const FormLabel = styled.label`
    display: block;
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 1rem;
    text-transform: uppercase;
    text-align: center;
  `;

  return (
    <React.Fragment>
      <ContainerFormMap>
        <Columns2>
          <FormS onSubmit={handleSubmit}>
            {loading ? (
              <Spinner />
            ) : (
              <React.Fragment>
                {/* Rest of your form code */}
                <div>
                  <h3 className="heading-3 ">
                    Remplir le formulaire pour que l'on vous fixe un rendez-vous
                  </h3>
                </div>
                <div>
                  <FormLabel htmlFor="full-name">Votre Nom Complet</FormLabel>
                  <InputLabel
                    // className="app1__input"
                    id="full-name"
                    type="text"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    placeholder="Prénom   Nom de famille"
                    required
                  />
                </div>
                <div>
                  <FormLabel htmlFor="email">Votre Courriel</FormLabel>
                  <InputLabel
                    // className="app1__input"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="email@example.com"
                    required
                  />
                </div>
                <div>
                  <FormLabel htmlFor="tel">Téléphone</FormLabel>
                  <InputLabel
                    // className="app1__input"
                    id="email"
                    type="tel"
                    pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="No de tél sans espace"
                    required
                  />
                </div>
                <div>
                  <FormLabel htmlFor="select-where">
                    Qui vous a référé?
                  </FormLabel>
                  <SelectLabel
                    // className="app1__input"
                    id="select-where"
                    value={option}
                    onChange={(e) => setOption(e.target.value)}
                    required
                  >
                    <option value="">Svp, choisir une option:</option>
                    <option value="Déjà patient de la clinique">
                      Déjà patient de la clinique
                    </option>
                    <option value="Ami,Famille,collegue">
                      Ami, Famille, collegue
                    </option>

                    <option value="Google">Google</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Instagram">Instagram</option>
                    <option value="Autre">Autre</option>
                  </SelectLabel>
                  {option === "Autre" && (
                    <InputAutre placeholder="Veuillez fournir plus de détails" />
                  )}
                </div>{" "}
                <OptionDentiste>
                  <FormLabel htmlFor="select-where">Votre dentiste?</FormLabel>
                  <SelectLabel
                    // className="app1__input"
                    id="select-where"
                    value={dentisteType}
                    onChange={handleDentisteTypeChange}
                    required
                  >
                    <option value="">Svp, choisir une option:</option>
                    <option value="pediatrique">Dentiste pédiatrique</option>
                    <option value="generaliste">Dentiste généraliste</option>
                  </SelectLabel>

                  {dentisteType && (
                    <SelectLabel
                      // className="app1__input"
                      value={dentiste}
                      onChange={handleDentisteChange}
                      required
                    >
                      <option value="">Svp, choisir une option:</option>
                      {dentisteOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </SelectLabel>
                  )}
                </OptionDentiste>
                {/* Rest of your form code */}
                <div className="box6">
                  <textarea
                    className="app1__textarea  "
                    name=""
                    value={info}
                    onChange={(e) => setInfo(e.target.value)}
                    id=""
                    cols="70"
                    rows="6"
                    placeholder="Information supplémentaire"
                  />
                </div>
              </React.Fragment>
            )}

            <ReCAPTCHA
              className="box7"
              // Free key
              //  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"

              // Key for local host
              // sitekey="6LecgRAkAAAAABLww5jCidwSC36p0TpKWHfj0fu1"
              // sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}

              // // key for https://csstesting-d79f0.web.app
              // sitekey="6LcDhBAkAAAAAAOxV34nWBiF3HKmAsZg-5zS_AUI"

              // Key for centresantesourire.com
              // sitekey="6Lfh7h4kAAAAAKWO6x2mO1KdwpJOuQJFF2mSCeJN"
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={handleOnChange}
            />

            <ButtonEnvoyer
              disabled={cap}
              className="btn box8"
              type="submit"
              // disabled={loading}
            >
              {loading ? "Loading..." : "Envoyer ma demande"}
            </ButtonEnvoyer>
          </FormS>
        </Columns2>

        <Columns2>
          <MapsV3 />
        </Columns2>
      </ContainerFormMap>
    </React.Fragment>
  );
}
