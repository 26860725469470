import styled from "styled-components";

const ServiceWrapper = styled.div`
  display: grid;
  align-content: center;
  background-color: #f9f7f6;
  padding:1.5rem 3rem;
  ${'' /* margin: 20px 10px; */}

  @media only screen and (min-width: 501px) {
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
  }
  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;
    justify-content: space-between;
  }
`;
export default ServiceWrapper;
