import styled from "styled-components";
import { css } from "styled-components";

export const MemberDmd = styled.img`
  ${(p) =>
    p.membre
      ? css`
          display: none;
        `
      : css`
          ${"" /* display: block; */}
          width: 25%;
          height: 25%;
          ${"" /* border-radius: 5%; */}
          margin-top: 10px;
          margin-bottom: auto;
          padding-right: 3rem;
          display: inline-block;
          float: left;
        `}
`;

const DentisteProfileWrappper = styled.div`
  display: grid;
  align-content: left;
  background-color: #f9f7f6;
  margin: 20px 10px 20px 10px;

  @media only screen and (max-width: 3000px) {
    grid-template-columns: 1fr 3fr;

    justify-content: space-between;
  }
  @media only screen and (max-width: 62.5em) {
    grid-template-columns: 1fr;
  }
  justify-content: space-between;
`;

