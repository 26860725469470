import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


var firebaseApp = firebase.initializeApp( {
  apiKey: "AIzaSyCx0j5zg3gapAmmtR8D6qYEo69g2NU1A_k",
  authDomain: "csstesting-d79f0.firebaseapp.com",
  projectId: "csstesting-d79f0",
  storageBucket: "csstesting-d79f0.appspot.com",
  messagingSenderId: "438055593068",
  appId: "1:438055593068:web:74eb213a198e4aaf74e449",
  measurementId: "G-32MYRMKHTG"
  
  });
   
  var db = firebaseApp.firestore();

  

  export default db;