import Sidebar from "../assets/wrappers/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "./Footer";
import DentistProfile from "../components/DentistProfile";
import roPic from "../assets/images/dentist/dmdDetails/dr-rene-ou-1.avif";
import mhPic from "../assets/images/dentist/dmdDetails/dr-marwan-hbeika-1.avif";
import dvPic from "../assets/images/dentist/dmdDetails/dr-vu-1.avif";
import elPic from "../assets/images/dentist/dmdDetails/dre-Larochelle-1.avif";
import nlPic from "../assets/images/dentist/dmdDetails/dre-nathalie-larouche-1.avif";
import rvPic from "../assets/images/dentist/dmdDetails/dre-roxanne-vallee-1.avif";
import saPic from "../assets/images/dentist/dmdMainPage/stephanie.webp";
import ffPic from "../assets/images/dentist/dmdDetails/dre-florence-fleury.avif";
import kpPic from "../assets/images/dentist/dmdDetails/dre-kalina-pluta-1.avif";
import anPic from "../assets/images/dentist/dmdDetails/alain-nohra .avif";
// import femaleProfile from "../assets/images/dentist/dmdDetails/femaleProfile.avif";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import acdq from "../assets/images/dentist/dmdDetails/acdq.avif";
// import odq from "../assets/images/dentist/dmdDetails/odq.avif";

const Team = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Notre équipe | Dentiste à Verdun</title>
        <meta
          name="description"
          content=" Rencontrez notre équipe dentaire dévouée pour tous vos besoins en santé bucco-dentaire. Des services de qualité, de la prévention à la chirurgie, pour un sourire radieux."
        />
      </Helmet>
      <Sidebar>
        <Navbar />
      </Sidebar>
      <div id="dr-rene-ou" />
      <div id="dr-marwan-hbeika" />

      <DentistProfile
        dmdPic={roPic}
        titre="Dentiste"
        name="Dr René Ou"
        description1="Gradué de l'Université de Montréal en 2004"
        description2="Formation en finances au HEC en 2004-2005"
        description3="Membre de l'équipe depuis 2005"
        // membre={odq}
        // membre2={acdq}
      />

      <div id="dr-duy-dat-vu" />
      <DentistProfile
        dmdPic={mhPic}
        titre="Dentiste"
        name="Dr Marwan Hbeika"
        description1="Gradué de médecine dentaire de l’Université de Montréal en 2002"
        description2="Résidence multidisciplinaire en 2003"
        description3="Hôpital Général Juif de Montréal"
        description4="Fait partie de l’équipe depuis 2005"
        description5="Dentisterie générale, couronnes, ponts prothèse, chirurgies, traitement de canal. Restauration sur implants"
        // membre={odq}
        // membre2={acdq}
      />

      <DentistProfile
        dmdPic={dvPic}
        name="Dr Duy Dat Vu"
        titre="Dentiste pédiatrique"
        title="Formation"
        description1="
                  - Juin 2000: obtenu certificat en dentisterie pédiatrique de la Faculté de médecine dentaire de l’Université de Montréal (1998-2000)."
        description2="- Juin 2000- obtenu Fellowship du Collège royal des dentistes du Canada."
        description3="- Juillet 1998: obtenu M.Sc. en Sciences biomédicales de la Faculté de médecine de l’Université de Montréal (1996-1998)."
        description4="- Juin 1995: mérité le prix de l’American Association of Orthodontists et obtenu D.M.D. de la Faculté de médecine dentaire de l’Université Laval, Québec (1991-1995)."
        title2="Expérience professionnelles"
        exp1="Juillet 2012-présent: directeur et professeur adjoint, Programme de maîtrise en médecine dentaire, option dentisterie pédiatrique, Université de Montréal / Hôpital Ste-Justine."
        exp2="Juillet 2008-Juillet 2012: directeur, Pediatric Surgical Services, Division of Dentistry, Montreal Children’s Hospital."
        exp3="Juin 2006-présent: examinateur en dentisterie pédiatrique au Collège royal des dentistes du Canada."
        exp4="Janvier 2005-présent: membre du comité de discipline de l’Ordre des dentistes du Québec."
        exp5="Janvier 2004-2015: président, Association des dentistes pédiatriques du Québec."
        // membre={odq}
      />
      <div id="dr-elaine-larochelle" />
      <div id="dre-nathalie-larouche" />
      <DentistProfile
        dmdPic={elPic}
        titre="Dentiste pédiatrique"
        name="Dr Elaine Larochelle"
        description1="Docteure Larochelle a obtenu son doctorat en médecine dentaire de l’Université de Montréal en 1985."
        description2="Après deux ans en pratique privée, elle complète un diplôme en dentisterie pédiatrique à l’Université de Toronto."
        description3="Depuis 1989, elle exerce la dentisterie pédiatrique en cabinet privé à Verdun. "
        description4="Après de nombreuses années à l’Hôpital Ste-Justine, elle est présentement chargée d’enseignement clinique à la Faculté de médecine dentaire de l’Université de Montréal."
        description5="Sa passion pour le bien-être et la santé dentaire des enfants ne s’est pas atténuée au fil des années."
        // membre={odq}
      />
      <div id="dre-roxanne-vallee" />
      <DentistProfile
        dmdPic={nlPic}
        titre="Dentiste"
        name="Dr Nathalie Larouche"
        description1="Université de Montréal, doctorat en médecine dentaire, graduée en 1992"
        description2="Université Laval, diplôme de 2e cycle en dentisterie multidisciplinaire, graduée en 1993"
        // membre={odq}
        // membre2={acdq}
      />
      <div id="dre-kalina-pluta" />
      <DentistProfile
        dmdPic={rvPic}
        titre="Dentiste"
        name="Dr Roxane Vallée"
        description1="Graduée de l’Université de Montréal en 1998"
        description2="Université de Montréal, diplôme de 2e cycle en dentisterie multidisciplinaire, graduée en 1999"
        description3="Travaille avec l’équipe depuis 1999"
        // membre={odq}
        // membre2={acdq}
      />
      <div id="dr-stephanie-allardPuscas" />
      <DentistProfile
        dmdPic={kpPic}
        titre="Orthodontiste"
        name="Dr Kalina Pluta"
        description1="1993-1996: Baccalauréat en Sciences (microbiologie et immunologie), Université McGill"
        description2="1996-2000: Doctorat en Médecine Dentaire, Université McGill"
        description3="2000-2001: Résidence multidisciplinaire à l’Hôpital Royal Victoria"
        description4="2001-2003: Spécialisation en Orthodontie, Université de Boston"
        description5="2003-2007: J’ai travaillé comme orthodontiste aux États-Unis"
        description6="Depuis 2007: Orthodontiste au Québec. Bureau à Verdun et à Pointe-Claire"
        // membre={odq}
      />
      <div id="dr-fleury-amann" />
      <DentistProfile
        dmdPic={saPic}
        titre="Dentiste"
        name="Dre Stéphanie Allard-Puscas"
        description1="Doctorat en médecine dentaire, graduée de l’Université de Montréal en 2021"
        description2="S’est joint à l’équipe du Centre Santé Sourire en 2022"
        description3="Exerce la dentisterie générale avec un intérêt particulier pour ces divers domaines: chirurgie/extraction des dents de sagesse, restauration esthétique, couronne, pont, prothèse, parodontie, traitement de canal et plaque occlusale/ protecteur buccal."
        // membre={odq}
        // membre2={acdq}
      />
      <div id="alain-nohra" />
      <DentistProfile
        dmdPic={ffPic}
        titre="Dentiste"
        name="Dre Florence Fleury Amann"
        description1="Gradué de l'Université de Montréal en 2011"
        description3="S'est joint à l'équipe du Centre Santé Sourire en 2022"
        // membre={odq}
        // membre2={acdq}
      />
      <DentistProfile
        dmdPic={anPic}
        titre="Denturologiste"
        name="Alain Nohra"
        description1="Gradué du Collège Edouard-Montpetit en 2010"
        description3="S'est joint à l'équipe du Centre Santé Sourire en 2016"
      />
      <Footer />
    </HelmetProvider>
  );
};
export default Team;
