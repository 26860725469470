import Sidebar from "../assets/wrappers/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "./Footer";

import FormPatienV1 from "../components/FormPatientV1";
import { Helmet, HelmetProvider } from "react-helmet-async";

const RendezVous = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Rendez-vous | Dentiste à Verdun</title>
        <meta
          name="description"
          content="Trouvez un dentiste à Verdun et réservez votre rendez-vous dès maintenant pour des soins dentaires de qualité et un sourire éclatant."
        />
      </Helmet>
      <Sidebar>
        <Navbar />
      </Sidebar>
      <FormPatienV1 id="rv" />

      <Footer />
    </HelmetProvider>
  );
};
export default RendezVous;
