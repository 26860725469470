import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import RendezVous from "./pages/RendezVous";
import NosDentistes from "./pages/NosDentistes";
// import Clinique from "./pages/Clinique";
import ServiceWeb from "./pages/ServiceWeb";
import Urgence from "./pages/Urgence";
import ErrorPage404 from "./pages/ErrorPage404";
// import PolitiqueConfidentialite from "./pages/PolitiqueConfidentialite";
// import Emploi from "./pages/Emploi";
// import Sitemap from "./sitemap.xml"
// import QuestionnaireMedicale from "./pages/MedicalForm"
// import Test from "./pages/Test"
// import Test3 from "./pages/Test3"
// import FormPatientV2 from "./components/PDF/PDFFormContainer"



function App() {
  return (
    // orange(sur le site web) vert(coding pages)
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/urgence" element={<Urgence />} />
        <Route path="/rendezvous" element={<RendezVous />} />
        {/* <Route path="/nous-joindre" element={<RendezVous />} /> */}
        <Route path="/nosdentistes" element={<NosDentistes />} />
        {/* <Route path="/clinique" element={<Clinique/>}/> */}
        <Route path="/service" element={<ServiceWeb />} />
        {/* <Route path="/politique-de-confidentialite" element={<PolitiqueConfidentialite />} /> */}
        {/* <Route path="/sitemap.xml" element={<Sitemap />} /> */}
        {/* <Route path="/emploi" element={<Emploi />} /> */}
        {/* <Route path="/questionnaire-medical" element={<QuestionnaireMedicale />} /> */}
        {/* <Route path="/test" element={<Test/>} /> */}
        {/* <Route path="/form" element={<FormPatientV2/>} /> */}
        <Route path="*" element={<ErrorPage404 />} />
       
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
