import logo from "../assets/images/logo/logo horizontal.webp";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import logoSmall from "../assets/images/logo/logo horizontal_mobile.webp";
// import logoMedium from "../assets/images/logo/logo_horizontal_desktop.webp";
// import logoLarge from "../assets/images/logo/logo_horizontal_big_desktop.webp";

const LogoSizeImg = styled.img`
  margin: 0px;
  padding: 11px 11px 11px 14px;
  height: 100px;

  @media (max-width: 400px) {
    width: 110px;
  height: 100px;
  }
  @media (min-width: 401px) {
    width: 150px;
    height: 100px;
  }
  @media (min-width: 679px) {
    width: 197px;
    height: 100px;
  }
`;

const Logo = () => {
  return (
    <Link to="/">
      <LogoSizeImg src={logo} alt="Centre sante sourire" />
      {/* <picture> */}
      {/* <source media="(max-width: 400px)" srcSet={logoSmall} />
      <source media="(min-width: 401px) and (max-width: 679px)" srcSet={logoMedium} />
      <source media="(min-width: 680px)" srcSet={logoLarge} />
      <img src={logoLarge} alt="Logo" style={{ margin: 0, padding: '11px 11px 11px 14px', height: '100px' }} />
    </picture> */}
    </Link>
  );
};
export default Logo;
